import { NgRedux } from '@angular-redux/store';
import { DOCUMENT, Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  AddVariantsToHeatingLeadRequest,
  HeatingVariantSummaryViewModel,
  HeatingVariantSummaryViewModelLeadSummaryViewModel,
  NboComparisonCostsRequest,
  NboComparisonCostsViewModel,
  NboHeatingLeadRequest,
  NboHeatingVariantRequest,
  PatchNboRequest,
  SaveNboHeatingRequest,
  SavePersonNboViewModel,
} from '@api-cc';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import {
  ADD_VARIANT_AND_DOWNLOAD_OFFER_HT,
  ADD_VARIANT_HT,
  ALL_HEAT_PUMP_TYPES,
  BUILDING_AGE_UNKNOWN,
  CHECK_LARGE_SYSTEM_AND_NAVIGATE,
  CONTACT_LARGE_SYSTEM,
  DIFFERENT_SOLUTION,
  DOWNLOAD_OFFER_HT,
  FINISH_HT,
  FINISH_HT_ONLY_EMAIL_SENDING,
  FINISH_SHARED_HT,
  GO_TO_CREATE_LEAD_FOR_SHARED_OFFER_PREVIEW,
  IHeatPumpType,
  INboFetched,
  IProductLineNboResult,
  ITemplateHt,
  IValueFormatted,
  SAVE_HT_AND_NAVIGATE,
} from '@theia-cc/ht/core';
import {
  FINISH_CONTACT,
  GO_TO_SUCCESS_URL,
  NAVIGATE_NEXT,
  ORIGIN_CALLBACK,
  buildUpLeadComment,
  caseAgnosticQueryParam,
  googleTagManagerPush,
  whitespacesHandler,
} from '@theia-cc/shared/helpers';
import { IConfigQueryParamsBase } from '@theia-cc/shared/models';
import {
  AlertService,
  BackendService,
  CantonService,
  ShareLinkService,
  UserInfoService,
} from '@theia-cc/shared/services';
import {
  BaseEffects,
  LeadMetaAction,
  SharedStoreEffect,
  WizardAction,
  getQueryParamStringHt,
} from '@theia-cc/shared/store';
import { FormatNumberPipe } from '@theia-cc/shared/util-ui';
import { HtStateAction } from './ht-state.action';
import { IAppStateHt, ICollectedDataHt } from './ht-state.reducer';
import { energyDemandKwhSelector } from './ht-state.selectors';

const APP_NAME = 'Customer Client WP';
const defaultVariantTitle = 'Customer Client';

export type ComparisonTechnologiesWithProductLines = {
  id: NboHeatingVariantRequest.HeatingManufacturerEnum;
  heatpumpType: NboHeatingVariantRequest.HeatingTypeEnum;
  heatpumpTypeId: IHeatPumpType['id'];
  heatingType: IHeatPumpType['heatingType'];
  costSummaryKey: IHeatPumpType['costSummaryKey'];
  installationCostKey: IHeatPumpType['installationCostKey'];
};

@Injectable()
export class HtStateEffect implements BaseEffects {
  constructor(
    private htStateAction: HtStateAction,
    private leadMetaAction: LeadMetaAction,
    private effect: SharedStoreEffect,
    private wizardAction: WizardAction,
    private backend: BackendService,
    private location: Location,
    private router: Router,
    private translate: TranslateService,
    private titleService: Title,
    private store: NgRedux<IAppStateHt>,
    private shareLinkService: ShareLinkService,
    private formatNumber: FormatNumberPipe,
    private alertService: AlertService,
    private userInfoService: UserInfoService,
    @Inject(DOCUMENT) private readonly document: Document,
    private cantonService: CantonService
  ) {}

  offerSentTag: boolean;
  offerShownTag: boolean;
  personalDataSaved: boolean;

  private static assignTranslationString(val): string {
    const strings = {
      RadiatorWithFeedBelow60: 'HT.HEAT_EMISSION.RADIATOR',
      FloorHeating: 'HT.HEAT_EMISSION.FLOOR_HEATING',
      RadiatorAndFloorHeating: 'HT.HEAT_EMISSION.RADIATOR_AND_FLOOR_HEATING',
      Before1972: 'HT.BUILDING_AGE.BEFORE_1972',
      Between1972And1997: 'HT.BUILDING_AGE.BETWEEN_1972_AND_1997',
      Between1998And2004: 'HT.BUILDING_AGE.BETWEEN_1998_AND_2004',
      After2004: 'HT.BUILDING_AGE.AFTER_2004',
      'building-age unknown': 'HT.BUILDING_AGE.UNKNOWN',
    };
    return strings[val] || '';
  }

  public get isMtServiceFlow() {
    const appState: IAppStateHt = this.store.getState();
    const getQueryParam = caseAgnosticQueryParam(appState.config.queryParams);
    return getQueryParam('partnerId') === 'mt' && getQueryParam('origin') === 'service';
  }

  public get isMt(): boolean {
    const appState: IAppStateHt = this.store.getState();
    const getQueryParam = caseAgnosticQueryParam(appState.config.queryParams);
    return getQueryParam('partnerId') === 'mt';
  }

  setNavbarPrimaryButtonPropertiesCheckLargeSystemAndNavigate = () => {
    this.wizardAction.setNavbarPrimaryButtonProperties({
      hidden: false,
      name: _('COMPONENT.NAVBAR.NEXT'),
      action: CHECK_LARGE_SYSTEM_AND_NAVIGATE,
      disabled: false,
    });
    this.wizardAction.allowNextStep();
  };

  buttonActions(action) {
    if (!action) return;

    const appState: IAppStateHt = this.store.getState();
    const currentStep = appState.wizard.currentStep;
    const { nboLeadIdHt, variantIdHt } = appState.collectedData;

    const patchRequest = (isForCallBack = false) =>
      this.personalDataSaved
        ? this.patchNboLead(isForCallBack)
        : this.updateHtNboPersonData(isForCallBack);

    switch (true) {
      case currentStep === 'contactemail':
        googleTagManagerPush({
          event: 'GAEvent',
          eventCategory: 'Conversion',
          eventAction: 'CRM Email',
          eventLabel: APP_NAME,
          eventValue: undefined,
        });
        break;
      case currentStep === 'contactcondensed':
        googleTagManagerPush({
          event: 'GAEvent',
          eventCategory: 'Conversion',
          eventAction: 'CRM Address',
          eventLabel: APP_NAME,
          eventValue: undefined,
        });
        break;
      case currentStep === 'contactinformation':
        googleTagManagerPush({
          event: 'GAEvent',
          eventCategory: 'Conversion',
          eventAction: 'CRM Address',
          eventLabel: APP_NAME,
          eventValue: undefined,
        });
        break;
      case currentStep === 'offerpreview' && action === GO_TO_SUCCESS_URL:
        googleTagManagerPush({
          event: 'GAEvent',
          eventCategory: 'Conversion',
          eventAction: 'Request Advise',
          eventLabel: APP_NAME,
          eventValue: undefined,
        });
        break;
      case currentStep === 'offerpreview' && (action === FINISH_HT || action === FINISH_SHARED_HT):
        googleTagManagerPush({
          event: 'GAEvent',
          eventCategory: 'Conversion',
          eventAction: 'Offer Download',
          eventLabel: APP_NAME,
          eventValue: undefined,
        });
        break;
    }

    switch (action) {
      case NAVIGATE_NEXT:
        this.effect.navigateNext();
        break;
      case GO_TO_SUCCESS_URL:
        this.leadMetaAction.storeLeadTracers(PatchNboRequest.LeadStatusEnum.Callback);
        if (!nboLeadIdHt) {
          this.goToSuccessURL();
        } else {
          patchRequest(true).then(() => {
            this.goToSuccessURL();
          });
        }
        break;
      case SAVE_HT_AND_NAVIGATE:
        this.wizardAction.showLoadingSpinner();
        this.createHtNboLead()
          .then(data => {
            this.handleGoogleTagsAfterInternalLeadIdIsPresent(
              data.InternalLeadId.toString(),
              data.LeadId
            );
            this.effect.navigateNext();
          })
          .then(() => this.wizardAction.hideLoadingSpinner());
        break;
      case FINISH_HT:
        this.wizardAction.showLoadingSpinner();
        this.saveAndFinishHt(appState.collectedData.nboLeadIdHt, appState.config.queryParams).then(
          () => {
            this.wizardAction.hideLoadingSpinner();
            this.goToSuccessURL();
          }
        );
        break;
      case FINISH_HT_ONLY_EMAIL_SENDING:
        this.offerSentTag = true;
        this.wizardAction.showLoadingSpinner();

        patchRequest()
          .then(() => this.addVariantToLead({ isFinalVariant: true }))
          .then(() => {
            this.wizardAction.hideLoadingSpinner();
            this.goToSuccessURL();
          });
        break;
      case FINISH_SHARED_HT:
        this.wizardAction.showLoadingSpinner();
        patchRequest()
          .then(() =>
            this.saveAndFinishHt(appState.collectedData.nboLeadIdHt, appState.config.queryParams)
          )
          .then(() => {
            this.wizardAction.hideLoadingSpinner();
            this.goToSuccessURL();
          });
        break;
      case GO_TO_CREATE_LEAD_FOR_SHARED_OFFER_PREVIEW:
        this.effect.navigateWithoutRestfullOfferPreviewParams(appState)('contactemail');
        break;
      case FINISH_CONTACT:
        this.leadMetaAction.storeLeadStatus(PatchNboRequest.LeadStatusEnum.Callback);
        this.leadMetaAction.storeLeadTracers(PatchNboRequest.LeadStatusEnum.Callback);
        this.wizardAction.showLoadingSpinner();
        const finishContactRequest: Promise<unknown> = nboLeadIdHt
          ? patchRequest(true)
          : this.createContactNboLead().then(data => {
              this.handleGoogleTagsAfterInternalLeadIdIsPresent(
                data.InternalLeadId.toString(),
                data.LeadId
              );
              return data;
            });
        finishContactRequest.then(() => {
          this.wizardAction.hideLoadingSpinner();
          this.goToSuccessURL();
        });
        break;
      case CHECK_LARGE_SYSTEM_AND_NAVIGATE:
        this.wizardAction.showLoadingSpinner();
        this.getProductLinesWithPowerConsumptionSelected()
          .then((productLines: IProductLineNboResult[]) => {
            const isLargeSystem = productLines.length === 0;
            this.htStateAction.storeLargeSystem(isLargeSystem);
            if (isLargeSystem) {
              this.leadMetaAction.storeLeadTracers('XL-HP');
              this.leadMetaAction.storeLeadTag('XL-HP');
            } else {
              this.leadMetaAction.storeLeadTracers(undefined);
              this.leadMetaAction.storeLeadTag(undefined);
            }
            this.effect.navigateNext();
          })
          .then(() => this.wizardAction.hideLoadingSpinner());
        break;
      case CONTACT_LARGE_SYSTEM:
        const redirect = () => {
          this.router.navigate(['/contactlargesystem'], {
            queryParams: this.getQueryParamObject({}),
            queryParamsHandling: 'merge',
          });
        };
        const getCaseAgnosticQueryParam = caseAgnosticQueryParam(appState.config.queryParams);
        if (
          currentStep === 'comparison' &&
          getCaseAgnosticQueryParam('compareTechnologies') === 'true'
        ) {
          redirect();
          break;
        }

        if (getCaseAgnosticQueryParam('compareTechnologies') !== 'true') {
          redirect();
        } else {
          this.effect.navigateNext();
        }
        break;
      case ADD_VARIANT_AND_DOWNLOAD_OFFER_HT:
        this.offerShownTag = true;
        this.wizardAction.showLoadingSpinner();

        patchRequest()
          .then(() => this.addVariantToLead({ isFinalVariant: false }))
          .then(res => this.backend.getHtNboOfferPreviewPDF(nboLeadIdHt, res[0].VariantId))
          .then(() => {
            this.wizardAction.hideLoadingSpinner();
            this.goToSuccessURL();
          });
        break;
      case ADD_VARIANT_HT:
        this.wizardAction.showLoadingSpinner();
        patchRequest()
          .then(() => this.addVariantToLead({ isFinalVariant: false }))
          .then(() => {
            this.wizardAction.hideLoadingSpinner();
          });
        break;
      case DIFFERENT_SOLUTION:
        patchRequest(true).then(() => {
          this.goToSuccessURL();
          this.wizardAction.hideLoadingSpinner();
        });
        break;
      case DOWNLOAD_OFFER_HT:
        this.wizardAction.showLoadingSpinner();
        this.backend.getHtNboOfferPreviewPDF(nboLeadIdHt, variantIdHt).then(() => {
          this.wizardAction.hideLoadingSpinner();
        });
        break;
      default:
        break;
    }
  }

  // Push the internalLeadId prop to the datalayer as soon as this prop is present
  handleGoogleTagsAfterInternalLeadIdIsPresent(internalLeadId: string, leadId: string) {
    googleTagManagerPush({
      event: 'GAEvent',
      eventCategory: 'UserId',
      eventAction: 'Get TheiaLeadId ',
      eventLabel: internalLeadId,
    });
    googleTagManagerPush({
      event: 'GAEvent',
      eventCategory: 'UserId',
      eventAction: 'Get ExtLeadId',
      eventLabel: leadId,
    });
  }

  goToSuccessURL() {
    const state: IAppStateHt = this.store.getState();
    const urls = state.config.org.successPageUrls;
    const leadId = state.collectedData.nboLeadIdHt;
    window.location.href = `${urls[state.currentLanguage]}?leadId=${
      leadId || ''
    }&${getQueryParamStringHt(state)}`;
  }

  goToContact() {
    this.leadMetaAction.storeLeadTracers(PatchNboRequest.LeadStatusEnum.Callback);

    this.router.navigate(['/contact'], {
      queryParams: this.getQueryParamObject(),
      queryParamsHandling: 'merge',
    });
  }

  getQueryParamObject(additionalParameters?: {}) {
    const state: IAppStateHt = this.store.getState();
    const params = { ...additionalParameters, ...state.config.queryParams };
    return Object.keys(params).length > 0 ? params : {};
  }

  getProductLinesWithPowerConsumptionSelected() {
    const appState: IAppStateHt = this.store.getState();
    const { powerConsumptionSelected, heatingEmission } = appState.collectedData;

    return this.backend.getHtNboProductlines(powerConsumptionSelected, heatingEmission);
  }

  getConversionFactor = (heatingType: string) => {
    return this.backend.postHtEnergyConversion(heatingType);
  };

  calculateHeatingPower = payload => {
    return this.backend.postHtHeatingPower(payload);
  };

  getProductLines = energyDemandKwh => {
    const appState: IAppStateHt = this.store.getState();
    const { heatingEmission } = appState.collectedData;
    return this.backend.getHtNboProductlines(energyDemandKwh, heatingEmission);
  };

  calculateCo2Emissions = () => {
    const state: IAppStateHt = this.store.getState();
    return this.backend.postComparisonCo2Emission({
      EnergyDemand: state.collectedData.powerConsumptionSelected || null,
    });
  };

  calculateComparisonTechnologyInvestment = () => {
    const state: IAppStateHt = this.store.getState();

    this.htStateAction.nboComparisonClear();

    return this.getProductLines(state.collectedData.powerConsumptionSelected).then(
      (productLines: IProductLineNboResult[]) => {
        const comparisonTechnologiesWithProductLines = ALL_HEAT_PUMP_TYPES.filter(
          ({ value, costSummaryKey, installationCostKey }) =>
            installationCostKey &&
            costSummaryKey &&
            value.some(type => productLines.find(({ HeatingType }) => type === HeatingType))
        );
        const variants = comparisonTechnologiesWithProductLines.map(type =>
          this.getFirstTemplateIdForHeatPumpType(productLines, type, state)
        );

        return Promise.all([
          ...variants.map(variant => {
            return this.postHtCalculateNbo(variant);
          }),
        ]).then(nboFetchedHt => {
          this.htStateAction.nboStoreComparisonResult(nboFetchedHt);
          return this.backend
            .postComparisonInvestment({
              InvestmentNewHeatpump: nboFetchedHt.find(item => item.id === variants[0].id).Brutto,
              HeatingPower: state.collectedData.powerConsumptionSelected || null,
            })
            .then(data => ({
              comparisonTechnologiesWithProductLines: variants,
              investmentCosts: {
                ...data,
                ...variants.reduce((res, { heatpumpType, installationCostKey }) => {
                  res[installationCostKey] = nboFetchedHt.find(
                    item => item.heatpumpType === heatpumpType
                  ).Brutto;

                  return res;
                }, {}),
              },
            }));
        });
      }
    );
  };

  private getFirstTemplateIdForHeatPumpType(
    productLines: IProductLineNboResult[],
    heatPumpType: IHeatPumpType,
    state: IAppStateHt
  ): ComparisonTechnologiesWithProductLines {
    // first type in heatPumpTypes has more priority,
    // that's why find through heatPumpTypes
    const availableProductLines = heatPumpType.value
      .flatMap(type =>
        productLines.filter((productLine: IProductLineNboResult) => {
          return productLine.HeatingType === type;
        })
      )
      .filter(productLine => productLine !== undefined);
    const manufacturer = state?.collectedData?.nboSelectedTemplateIdHt;
    const productLineOfTemplate =
      availableProductLines.find(
        ({ HeatingManufacturer }) => HeatingManufacturer === manufacturer
      ) || availableProductLines[0];
    return {
      id: productLineOfTemplate.HeatingManufacturer,
      heatpumpType: productLineOfTemplate.HeatingType,
      heatpumpTypeId: heatPumpType.id,
      heatingType: heatPumpType.heatingType,
      costSummaryKey: heatPumpType.costSummaryKey,
      installationCostKey: heatPumpType.installationCostKey,
    };
  }

  calculateComparisonCostSummary = (
    data: NboComparisonCostsRequest
  ): Promise<NboComparisonCostsViewModel> => {
    const state: IAppStateHt = this.store.getState();
    return this.backend.postComparisonCostSummary({
      EnergyDemand: state.collectedData.powerConsumptionSelected || null,
      CostLiterOil: data.CostLiterOil,
      CostKwhElectricity: data.CostKwhElectricity,
      TaxCostTonneCo2: data.TaxCostTonneCo2,
    }) as Promise<NboComparisonCostsViewModel>;
  };

  postHtCalculateNbo(arg: {
    id: NboHeatingVariantRequest.HeatingManufacturerEnum;
    heatpumpType?: NboHeatingVariantRequest.HeatingTypeEnum;
  }): Promise<INboFetched> {
    const { id, heatpumpType } = arg;
    const state: IAppStateHt = this.store.getState();
    const collectedData: ICollectedDataHt = state.collectedData;
    const { heatingArea, heatingType, insulation } = collectedData;
    const {
      buildingGroundArea,
      buildingRoofArea,
      buildingRoofOverhangArea,
      buildingWallArea,
      buildingVolume,
      buildingLevels,
      buildingRoofShape,
      aboveSea,
    } = collectedData.buildingData;

    const { userAddress } = collectedData;

    const requestHeatPumpType: NboHeatingVariantRequest.HeatingTypeEnum =
      heatpumpType || collectedData.heatPumpType;

    const projectType: NboHeatingLeadRequest.ProjectTypeEnum =
      NboHeatingLeadRequest.ProjectTypeEnum.Replacement;

    // Set correct 'BuildingPreviousRenovationLastTenYears' values
    const buildingPreviousRenovationLastTenYears: NboHeatingLeadRequest.BuildingPreviousRenovationLastTenYearsEnum[] =
      this.buildUpBuildingPreviousRenovationLastTenYearsArray(insulation);

    // Set correct value for BuildingYearOfConstruction
    // When choosing NewBuilding for ProjectType, then the question for BuildingYearOfConstruction should not be displaed.
    // But If NewBuilding is chosen, the value sent from the NBO to the backend for BuidlingYearOfConstruction should be NewBuilding as well.
    const buildingYearOfConstruction: NboHeatingLeadRequest.BuildingYearOfConstructionEnum =
      this.buildUpBuildingYearOfConstruction(collectedData.buildingAge, projectType);

    const payload = {
      LeadRequest: {
        ProjectType: projectType,
        CurrentHeatGeneratorType: heatingType,
        CurrentHeatDissipation: collectedData.heatingEmission,
        BuildingYearOfConstruction: buildingYearOfConstruction,
        BuildingPreviousRenovationLastTenYears: buildingPreviousRenovationLastTenYears,
        AveragedCurrentEnergyDemandKwh: collectedData.powerConsumptionSelected || null,
        NumberInhabitants: collectedData.familyMemberCount || null,
        BuildingGroundArea: buildingGroundArea || null,
        BuildingRoofArea: buildingRoofArea || null,
        BuildingRoofOverhangArea: buildingRoofOverhangArea || null,
        BuildingWallArea: buildingWallArea || null,
        BuildingVolume: buildingVolume || null,
        BuildingEnergyReferenceArea: heatingArea || null,
        BuildingLevels: buildingLevels || null,
        BuildingRoofShape: (buildingRoofShape && buildingRoofShape.toString()) || null,
        BuildingAboveSea: aboveSea || null,
        LatLonCoordinates:
          userAddress.lat && userAddress.lon
            ? `lat:${userAddress.lat}, lon:${userAddress.lon}`
            : null,
        Canton: this.cantonService.getCanton(userAddress.zip),
      },
      VariantRequest: {
        VariantTitle: id ? id : defaultVariantTitle,
        IsSendMail: true,
        ProjectType: projectType,
        HeatingType: requestHeatPumpType,
        HeatingManufacturer: id,
        EnergyDemandKwh: energyDemandKwhSelector(state),
        SmartEnergyConnection: this.getSmartEnergyConnection(id, requestHeatPumpType),
        ResponsibleConstructionWorkType:
          collectedData.responsibleConstructionWork ||
          (this.isMt
            ? NboHeatingVariantRequest.ResponsibleConstructionWorkTypeEnum.Desired
            : NboHeatingVariantRequest.ResponsibleConstructionWorkTypeEnum.Undesired),
        ResponsibleElectricalConnection:
          collectedData.responsibleElectricalConnection ||
          NboHeatingVariantRequest.ResponsibleElectricalConnectionEnum.Undesired,
        GeothermalProbe:
          collectedData.geothermalProbe ||
          (this.isMt
            ? NboHeatingVariantRequest.GeothermalProbeEnum.PartOfOffer
            : NboHeatingVariantRequest.GeothermalProbeEnum.OnSite),
        DismantlingTank: collectedData.dismantleTank,
      },
    };

    return this.backend.postHtCalculateNbo(payload).then(result => ({
      id,
      heatpumpType: requestHeatPumpType,
      ...(result as HeatingVariantSummaryViewModel),
    }));
  }

  calculateNbo = (arg: {
    id: NboHeatingVariantRequest.HeatingManufacturerEnum;
    heatpumpType?: NboHeatingVariantRequest.HeatingTypeEnum;
  }) => {
    return this.postHtCalculateNbo(arg).then(nboStoreData => {
      this.htStateAction.nboStoreResult(nboStoreData);
      this.htStateAction.storeHeatingPower(nboStoreData.HeatingPower);

      return nboStoreData;
    });
  };

  async createHtNboLead() {
    const state: IAppStateHt = this.store.getState();
    const collectedData: ICollectedDataHt = state.collectedData;
    const { hubspotTrackingId, queryParams } = state.config;
    const previewImage = collectedData.previewImage;

    const projectType: NboHeatingLeadRequest.ProjectTypeEnum =
      NboHeatingLeadRequest.ProjectTypeEnum.Replacement;

    // Set correct value for BuildingYearOfConstruction
    // When choosing NewBuilding for ProjectType, then the question for BuildingYearOfConstruction should not be displaed.
    // But If NewBuilding is chosen, the value sent from the NBO to the backend for BuidlingYearOfConstruction should be NewBuilding as well.
    const buildingYearOfConstruction: NboHeatingLeadRequest.BuildingYearOfConstructionEnum =
      this.buildUpBuildingYearOfConstruction(collectedData.buildingAge, projectType);

    const leadComment = buildUpLeadComment(
      collectedData.leadComment,
      state.config.versionNumber,
      state.device.userAgent,
      this.getCallBackTime(state.collectedData.leadCallbackTime),
      collectedData,
      queryParams,
      this.shareLinkService.generateShareLink(this.document.location, state)
    );

    const { userAddress } = collectedData;

    const payload: SaveNboHeatingRequest = {
      LeadRequest: {
        ProjectType: projectType,
        BuildingYearOfConstruction: buildingYearOfConstruction,
        AveragedCurrentEnergyDemandKwh: collectedData.powerConsumptionSelected || null,
        NumberInhabitants: collectedData.familyMemberCount || null,
        Canton: this.cantonService.getCanton(userAddress.zip),
      },
      VariantRequests: [],
      PersonData: {
        Email: collectedData.user.email,
        Language: this.setCorrectLanguageEnumValue(state.currentLanguage),
      },
      LeadComment: leadComment,
      LeadTracers: '',
      LeadStatus: this.isMtServiceFlow
        ? PatchNboRequest.LeadStatusEnum.Callback
        : PatchNboRequest.LeadStatusEnum.Incomplete,
      ...collectedData.leadCallbackTime,
      SituationImage3D: previewImage,
      HubspotTrackingId: hubspotTrackingId,
      RelatedLeadPublicId: collectedData.relatedLeadPublicId,
      ThirdPartyCustomerNumber: caseAgnosticQueryParam(queryParams)('ThirdPartyCustomerNumber'),
      ShareUrl: this.shareLinkService.generateShareLink(this.document.location, state),
    };

    const data = await this.backend.postHtSaveNboData(payload);
    this.htStateAction.storeNboLeadId(data.LeadId);
    this.htStateAction.storeInternalLeadId(data.InternalLeadId);
    this.personalDataSaved = false;
    return data;
  }

  async updateHtNboPersonData(isForCallBack = false, LeadStatus?: PatchNboRequest.LeadStatusEnum) {
    const state: IAppStateHt = this.store.getState();
    const collectedData: ICollectedDataHt = state.collectedData;
    const { queryParams } = state.config;

    const LeadComment: string = buildUpLeadComment(
      collectedData.leadComment,
      state.config.versionNumber,
      state.device.userAgent,
      this.getCallBackTime(state.collectedData.leadCallbackTime),
      collectedData,
      state.config.queryParams,
      this.shareLinkService.generateShareLink(this.document.location, state),
      isForCallBack
    );

    const LeadTag = this.getLeadTag(collectedData.leadTag);

    const payload: PatchNboRequest = {
      LeadTag,
      LeadTracers: this.getLeadTracers(
        collectedData,
        this.getSmartEnergyConnection(
          collectedData.nboSelectedTemplateIdHt,
          collectedData.heatPumpType
        )
      ),
      Address: {
        Title: collectedData.user.title,
        CompanyName: whitespacesHandler(collectedData.user.companyName) || null,
        FirstName: whitespacesHandler(collectedData.user.firstName),
        LastName: whitespacesHandler(collectedData.user.lastName),
        PhoneNumber: whitespacesHandler(collectedData.user.telephone),
        ZipCode: collectedData.userAddress.zip || null,
        City: collectedData.userAddress.place || null,
        Street: collectedData.userAddress.street || null,
      },
      LeadStatus:
        LeadTag.indexOf('other solution') !== -1
          ? PatchNboRequest.LeadStatusEnum.Callback
          : LeadStatus || this.isMtServiceFlow
          ? PatchNboRequest.LeadStatusEnum.Callback
          : PatchNboRequest.LeadStatusEnum.Complete,
      ThirdPartyCustomerNumber: caseAgnosticQueryParam(queryParams)('ThirdPartyCustomerNumber'),
      ...collectedData.leadCallbackTime,
    };

    const data = await this.backend.patchHtNboPersonData(payload);
    await this.userInfoService.sendAdditionalAddresses(collectedData.nboLeadIdHt);

    this.personalDataSaved = true;
    return data;
  }

  addVariantToLead = (arg: { isFinalVariant: boolean }) => {
    const state: IAppStateHt = this.store.getState();
    const collectedData: ICollectedDataHt = state.collectedData;
    const { nboSelectedTemplateIdHt } = collectedData;
    const { heatingArea, insulation } = collectedData;
    const {
      buildingGroundArea,
      buildingRoofArea,
      buildingRoofOverhangArea,
      buildingWallArea,
      buildingVolume,
      buildingLevels,
      buildingRoofShape,
      aboveSea,
    } = collectedData.buildingData;
    const { userAddress } = collectedData;

    const comments = [];
    comments.push(`heating type: ${collectedData.heatingType}`);
    comments.push(`family members: ${collectedData.familyMemberCount}`);

    const projectType: NboHeatingLeadRequest.ProjectTypeEnum =
      NboHeatingLeadRequest.ProjectTypeEnum.Replacement;

    // Set correct 'BuildingPreviousRenovationLastTenYears' values
    const buildingPreviousRenovationLastTenYears: NboHeatingLeadRequest.BuildingPreviousRenovationLastTenYearsEnum[] =
      this.buildUpBuildingPreviousRenovationLastTenYearsArray(insulation);

    // Set correct value for BuildingYearOfConstruction
    // When choosing NewBuilding for ProjectType, then the question for BuildingYearOfConstruction should not be displaed.
    // But If NewBuilding is chosen, the value sent from the NBO to the backend for BuidlingYearOfConstruction should be NewBuilding as well.
    const buildingYearOfConstruction: NboHeatingLeadRequest.BuildingYearOfConstructionEnum =
      this.buildUpBuildingYearOfConstruction(collectedData.buildingAge, projectType);
    const payload: AddVariantsToHeatingLeadRequest = {
      LeadRequest: {
        ProjectType: projectType,
        CurrentHeatGeneratorType: collectedData.heatingType,
        CurrentHeatDissipation: collectedData.heatingEmission,
        BuildingYearOfConstruction: buildingYearOfConstruction,
        AveragedCurrentEnergyDemandKwh: collectedData.powerConsumptionSelected || null,
        NumberInhabitants: collectedData.familyMemberCount || null,
        BuildingPreviousRenovationLastTenYears: buildingPreviousRenovationLastTenYears,
        BuildingGroundArea: buildingGroundArea || null,
        BuildingRoofArea: buildingRoofArea || null,
        BuildingRoofOverhangArea: buildingRoofOverhangArea || null,
        BuildingWallArea: buildingWallArea || null,
        BuildingVolume: buildingVolume || null,
        BuildingEnergyReferenceArea: heatingArea || null,
        BuildingLevels: buildingLevels || null,
        BuildingRoofShape: (buildingRoofShape && buildingRoofShape.toString()) || null,
        BuildingAboveSea: aboveSea || null,
        LatLonCoordinates:
          userAddress.lat && userAddress.lon
            ? `lat:${userAddress.lat}, lon:${userAddress.lon}`
            : null,
        Canton: this.cantonService.getCanton(userAddress.zip),
      },
      VariantRequests: [
        {
          VariantTitle: `${defaultVariantTitle} ${arg.isFinalVariant ? 'Requested' : ''}`,
          IsSendMail: arg.isFinalVariant,
          ProjectType: projectType,
          HeatingType: collectedData.heatPumpType,
          HeatingManufacturer: nboSelectedTemplateIdHt,
          EnergyDemandKwh: energyDemandKwhSelector(state),
          SmartEnergyConnection: this.getSmartEnergyConnection(
            nboSelectedTemplateIdHt,
            collectedData.heatPumpType
          ),
          ResponsibleConstructionWorkType:
            collectedData.responsibleConstructionWork ||
            (this.isMt
              ? NboHeatingVariantRequest.ResponsibleConstructionWorkTypeEnum.Desired
              : NboHeatingVariantRequest.ResponsibleConstructionWorkTypeEnum.Undesired),
          ResponsibleElectricalConnection:
            collectedData.responsibleElectricalConnection ||
            (this.isMt
              ? NboHeatingVariantRequest.ResponsibleElectricalConnectionEnum.Desired
              : NboHeatingVariantRequest.ResponsibleElectricalConnectionEnum.Undesired),
          GeothermalProbe:
            collectedData.geothermalProbe ||
            (this.isMt
              ? NboHeatingVariantRequest.GeothermalProbeEnum.PartOfOffer
              : NboHeatingVariantRequest.GeothermalProbeEnum.OnSite),
          DismantlingTank: collectedData.dismantleTank,
        },
      ],
    };

    const isShowEmailSentMessage = arg.isFinalVariant && this.isMtServiceFlow;

    return this.backend
      .postHtVariantToLead(payload)
      .then(variant => {
        this.htStateAction.storeVariantId(variant[0].VariantId);
        this.htStateAction.storeHeatingPower(variant[0].HeatingPower);

        if (isShowEmailSentMessage) {
          this.alertService.alert({
            type: 'success',
            dismissible: true,
            dismissOnTimeout: 5000,
            content: this.translate.instant('HT.OFFERPREVIEW.EMAIL_SENT.SUCCESS'),
          });
        }

        return this.patchNboLead().then(() => variant);
      })
      .catch(() => {
        if (isShowEmailSentMessage) {
          this.alertService.alert({
            type: 'danger',
            dismissible: true,
            dismissOnTimeout: 5000,
            content: this.translate.instant('HT.OFFERPREVIEW.EMAIL_SENT.ERROR'),
          });
        }
      });
  };

  saveAndFinishHt(nboLeadIdHt: string | number, { mode }: IConfigQueryParamsBase) {
    return this.patchNboLead()
      .then(() => this.addVariantToLead({ isFinalVariant: true }))
      .then((val): Promise<HttpResponse<Blob> | void> => {
        const variantId = val[0].VariantId;
        return mode !== 'kiosk'
          ? this.backend.getHtNboOfferPreviewPDF(nboLeadIdHt, variantId)
          : Promise.resolve();
      });
  }

  createContactNboLead = async () => {
    const state: IAppStateHt = this.store.getState();
    const collectedData: ICollectedDataHt = state.collectedData;
    const { hubspotTrackingId, queryParams } = state.config;

    const LeadComment = buildUpLeadComment(
      collectedData.leadComment,
      state.config.versionNumber,
      state.device.userAgent,
      this.getCallBackTime(state.collectedData.leadCallbackTime),
      collectedData,
      queryParams,
      this.shareLinkService.generateShareLink(this.document.location, state),
      collectedData.leadTracers?.includes(PatchNboRequest.LeadStatusEnum.Callback)
    );

    const LeadTag = this.getLeadTag(collectedData.leadTag);

    const image = collectedData.previewImage;

    const projectType: NboHeatingLeadRequest.ProjectTypeEnum =
      NboHeatingLeadRequest.ProjectTypeEnum.Replacement;

    // Set correct value for BuildingYearOfConstruction
    // When choosing NewBuilding for ProjectType, then the question for BuildingYearOfConstruction should not be displaed.
    // But If NewBuilding is chosen, the value sent from the NBO to the backend for BuidlingYearOfConstruction should be NewBuilding as well.
    const buildingYearOfConstruction: NboHeatingLeadRequest.BuildingYearOfConstructionEnum =
      this.buildUpBuildingYearOfConstruction(collectedData.buildingAge, projectType);

    const { userAddress } = collectedData;

    const payload: SaveNboHeatingRequest = {
      LeadComment,
      LeadTag,
      LeadTracers: this.getLeadTracers(
        collectedData,
        this.getSmartEnergyConnection(
          collectedData.nboSelectedTemplateIdHt,
          collectedData.heatPumpType
        )
      ),
      LeadStatus: PatchNboRequest.LeadStatusEnum.Callback,
      ShareUrl: this.shareLinkService.generateShareLink(this.document.location, state),
      LeadRequest: {
        ProjectType: projectType,
        BuildingYearOfConstruction: buildingYearOfConstruction,
        AveragedCurrentEnergyDemandKwh: collectedData.powerConsumptionSelected || null,
        NumberInhabitants: collectedData.familyMemberCount || null,
        Canton: this.cantonService.getCanton(userAddress.zip),
      },
      VariantRequests: [],
      PersonData: {
        Email: collectedData.user.email || 'unknown',
        Language: this.setCorrectLanguageEnumValue(state.currentLanguage),
        Address: {
          Title: collectedData.user.title || 'None',
          FirstName: whitespacesHandler(collectedData.user.firstName) || 'unknown',
          LastName: whitespacesHandler(collectedData.user.lastName) || 'unknown',
          PhoneNumber: whitespacesHandler(collectedData.user.telephone) || 'unknown',
          ZipCode: collectedData.userAddress.zip || 'unknown',
          City: collectedData.userAddress.place || 'unknown',
          Street: collectedData.userAddress.street || 'unknown',
        },
      },
      Origin: collectedData.leadTracers?.includes(PatchNboRequest.LeadStatusEnum.Callback)
        ? ORIGIN_CALLBACK
        : undefined,
      SituationImage3D: image,
      HubspotTrackingId: hubspotTrackingId,
      ...collectedData.leadCallbackTime,
    };

    const data: HeatingVariantSummaryViewModelLeadSummaryViewModel =
      await this.backend.postHtSaveNboData(payload);
    this.htStateAction.storeNboLeadId(data.LeadId);
    this.htStateAction.storeInternalLeadId(data.InternalLeadId);

    await this.userInfoService.sendAdditionalAddresses(data.LeadId);

    return data;
  };

  patchNboLead(isForCallBack = false) {
    const state: IAppStateHt = this.store.getState();
    const collectedData: ICollectedDataHt = state.collectedData;
    const { queryParams } = state.config;

    const ShareUrl = this.shareLinkService.generateShareLink(this.document.location, state);
    const LeadComment = buildUpLeadComment(
      collectedData.leadComment,
      state.config.versionNumber,
      state.device.userAgent,
      this.getCallBackTime(collectedData.leadCallbackTime),
      collectedData,
      queryParams,
      ShareUrl,
      isForCallBack
    );

    const LeadTag = this.getLeadTag(collectedData.leadTag);
    const LeadTracers = this.getLeadTracers(
      collectedData,
      this.getSmartEnergyConnection(
        collectedData.nboSelectedTemplateIdHt,
        collectedData.heatPumpType
      )
    );

    return this.backend.patchNboLead(state.collectedData.nboLeadIdHt, {
      ShareUrl,
      LeadComment,
      LeadTag,
      LeadTracers,
      LeadStatus:
        LeadTag.indexOf('other solution') !== -1 || this.isMtServiceFlow
          ? PatchNboRequest.LeadStatusEnum.Callback
          : collectedData.leadStatus,
    });
  }

  // Some handy handler methods to build up the correct payload
  // Build up the correct BuildingPreviousRenovationLastTenYearsArray for the API request
  buildUpBuildingPreviousRenovationLastTenYearsArray = (
    insulation: object
  ): NboHeatingLeadRequest.BuildingPreviousRenovationLastTenYearsEnum[] => {
    const result: NboHeatingLeadRequest.BuildingPreviousRenovationLastTenYearsEnum[] = [];

    Object.keys(insulation).forEach(key => {
      if (insulation[key]) {
        switch (key) {
          case 'WINDOWS':
            result.push(NboHeatingLeadRequest.BuildingPreviousRenovationLastTenYearsEnum.Windows);
            break;
          case 'ROOF':
            result.push(NboHeatingLeadRequest.BuildingPreviousRenovationLastTenYearsEnum.Roof);
            break;
          case 'FACADE':
            result.push(NboHeatingLeadRequest.BuildingPreviousRenovationLastTenYearsEnum.Facades);
            break;
          case 'BASEMENT':
            result.push(
              NboHeatingLeadRequest.BuildingPreviousRenovationLastTenYearsEnum.BasementFloor
            );
            break;
          default:
            break;
        }
      }
    });

    return result;
  };

  // Build up the correct buildingYearOfConstruction type
  buildUpBuildingYearOfConstruction = (
    buildingAge: NboHeatingLeadRequest.BuildingYearOfConstructionEnum | any,
    projectType: NboHeatingLeadRequest.ProjectTypeEnum
  ): NboHeatingLeadRequest.BuildingYearOfConstructionEnum | any => {
    let result: NboHeatingLeadRequest.BuildingYearOfConstructionEnum | any = buildingAge;

    if (projectType === NboHeatingLeadRequest.ProjectTypeEnum.NewBuilding) {
      result = NboHeatingLeadRequest.BuildingYearOfConstructionEnum.NewBuilding;
    }

    if (buildingAge === BUILDING_AGE_UNKNOWN) {
      result = NboHeatingLeadRequest.BuildingYearOfConstructionEnum.After2004;
    }
    return result;
  };

  // Get the correct language enum-value based on the internal language string
  setCorrectLanguageEnumValue(currentLanguage: string): SavePersonNboViewModel.LanguageEnum {
    switch (currentLanguage) {
      case 'de':
        return SavePersonNboViewModel.LanguageEnum.DE;
      case 'fr':
        return SavePersonNboViewModel.LanguageEnum.FR;
      case 'it':
        return SavePersonNboViewModel.LanguageEnum.IT;
      default:
        return SavePersonNboViewModel.LanguageEnum.DE;
    }
  }

  getCallBackTime(leadCallbackTime: ICollectedDataHt['leadCallbackTime']): string {
    return Object.values(leadCallbackTime).every(time => time !== true)
      ? 'unknown'
      : Object.entries(leadCallbackTime).reduce((res, [key, value]) => {
          return value === true
            ? `${res}${res.length ? ', ' : ''}${key.replace('IsCallbackTime', '')}`
            : res;
        }, '');
  }

  public valueFormatted(template: ITemplateHt, nboResult): IValueFormatted {
    const itemTitle = ALL_HEAT_PUMP_TYPES.find(
      ({ value, id }) =>
        id === template.data.heatpumpType || value.some(type => type === template.data.heatpumpType)
    )?.itemTitle;

    const {
      familyMemberCount,
      heatingType = '',
      heatingEmission,
      buildingAge,
      powerConsumptionSelected,
    } = this.store.getState().collectedData;

    return {
      NetInvestmentChf: this.roundNumber(nboResult.Brutto - (nboResult.ExpectedSubsidy || 0)),
      SubsidyMyclimateChf: nboResult.ExpectedSubsidy
        ? this.roundNumber(nboResult.ExpectedSubsidy)
        : nboResult.ExpectedSubsidy,
      TaxDeductionChf: this.roundNumber(nboResult.Brutto * 0.25 || 0),
      GrossChf: this.roundNumber(nboResult.Brutto || 0),
      FamilyMemberCount: familyMemberCount || 0,
      EnergyEfficiencyImprovement: 45,
      HeatingTypeString: heatingType && heatingType.toUpperCase().replace(/\s/g, ''),
      HeatingEmission: heatingEmission && HtStateEffect.assignTranslationString(heatingEmission),
      BuildingAge: buildingAge && HtStateEffect.assignTranslationString(buildingAge),
      PowerConsumption: this.roundNumber(
        powerConsumptionSelected || nboResult.AveragedCurrentEnergyDemandKwh
      ),
      heatingTypeName: itemTitle ? this.translate.instant(itemTitle) : '',
      title: this.translate.instant(template?.title || ''),
    };
  }

  private roundNumber(number): string {
    return this.formatNumber.transform(Math.ceil(number));
  }

  private getSmartEnergyConnection(
    manufacturer: NboHeatingVariantRequest.HeatingManufacturerEnum,
    heatPumpType: NboHeatingVariantRequest.HeatingTypeEnum
  ): NboHeatingVariantRequest.SmartEnergyConnectionEnum {
    const state: IAppStateHt = this.store.getState();
    const { config, collectedData } = state;
    const connectPvEnabled =
      config.templatesHt.find(item => item.id === manufacturer)?.connectPvEnabled?.[heatPumpType] ??
      true;

    return (
      (connectPvEnabled && collectedData.smartEnergy) ||
      NboHeatingVariantRequest.SmartEnergyConnectionEnum.Undesired
    );
  }

  private getLeadTag(leadTag: string): string {
    return [
      this.offerSentTag && 'Service - Offer sent',
      this.offerShownTag && 'Service - Offer shown',
      leadTag,
    ]
      .filter(tag => !!tag)
      .toString();
  }

  private getLeadTracers({ leadTracers }: ICollectedDataHt, SmartEnergyConnection: string) {
    const mainTracers = leadTracers || undefined;
    const additionalTracers =
      SmartEnergyConnection === NboHeatingVariantRequest.SmartEnergyConnectionEnum.Desired
        ? 'PV'
        : undefined;
    const notEmptyTracers = [mainTracers, additionalTracers].filter(
      tag => tag !== undefined && tag !== null
    );
    return (notEmptyTracers.length && notEmptyTracers.toString()) || undefined;
  }
}
