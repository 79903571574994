/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DirectoryEmailViewModel {
  EmailType: DirectoryEmailViewModel.EmailTypeEnum;
  Email: string;
}
export namespace DirectoryEmailViewModel {
  export type EmailTypeEnum = 'Mail' | 'Work' | 'Private' | 'Other' | 'Primary' | 'Reminder';
  export const EmailTypeEnum = {
    Mail: 'Mail' as EmailTypeEnum,
    Work: 'Work' as EmailTypeEnum,
    Private: 'Private' as EmailTypeEnum,
    Other: 'Other' as EmailTypeEnum,
    Primary: 'Primary' as EmailTypeEnum,
    Reminder: 'Reminder' as EmailTypeEnum,
  };
}
