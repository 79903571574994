/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NboEmobilityLeadViewModel {
  CarManufacturer?: string;
  CarId?: string;
  CarType_IsConfirmed?: boolean;
  BuildingType?: string;
  BuildingType_IsConfirmed?: boolean;
  PropertyOwnershipSituation?: string;
  PropertyOwnershipSituation_IsConfirmed?: boolean;
  FuseboxAge?: string;
  FuseboxAge_IsConfirmed?: boolean;
  MainFuseSize?: string;
  MainFuseSize_IsConfirmed?: boolean;
  UtilityCompany?: string;
  UtilityCompany_IsConfirmed?: boolean;
  ElectricityMeterNo?: string;
  ElectricityMeterNo_IsConfirmed?: boolean;
  RippleControlReceiverNo?: string;
  RippleControlReceiverNo_IsConfirmed?: boolean;
  HomecheckDate?: Date;
  HomecheckDate_IsConfirmed?: boolean;
  InstallationDate?: Date;
  InstallationDate_IsConfirmed?: boolean;
  ElectricitySupplySecurity?: string;
  ElectricitySupplySecurity_IsConfirmed?: boolean;
  RippleControlReceiverReserve?: string;
  RippleControlReceiverReserve_IsConfirmed?: boolean;
}
