import { NgRedux } from '@angular-redux/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  EmobilityVariantSummaryViewModelLeadSummaryViewModel,
  HeatingVariantSummaryViewModel,
  HeatingVariantSummaryViewModelLeadSummaryViewModel,
  NboComparisonCo2ViewModel,
  NboComparisonInvestmentViewModel,
  NboEmobilityCalculateRequest,
  NboEmobilityRequest,
  NboEnergyConversionViewModel,
  NboHeatingLeadRequest,
  NboLeadAddressViewModel,
  NboPostContactViewModel,
  NboProductlineViewModel,
  NboPutAddressRequest,
  PatchNboViewModel,
  PvVariantSummaryViewModel,
} from '@api-cc';
import {
  ENVIRONMENT,
  IEnvironment,
  ORIGIN,
  cleanEmptyProperties,
  isEmptyValue,
} from '@theia-cc/shared/helpers';
import { IAppStateBase } from '@theia-cc/shared/store';
import { saveAs } from 'file-saver';
import { EMPTY, firstValueFrom, map } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class BackendService {
  constructor(
    private http: HttpClient,
    private store: NgRedux<IAppStateBase>,
    @Inject(ENVIRONMENT) private environment: IEnvironment
  ) {}
  readonly apiEndpoint = this.environment.apiEndpoint;

  public static getOfferName(headers: HttpHeaders): string {
    const contentDisposition = headers.get('content-disposition');
    return (
      (contentDisposition &&
        contentDisposition.split(';').find(part => part.indexOf('filename=') !== -1)) ||
      'filename="Offerte.pdf"'
    )
      .trim()
      .replace('filename="', '')
      .replace('"', '');
  }

  private static patchPayload(payload: {}, queryParams): {} {
    const { origin, partnerId = '' } = queryParams;
    payload['Origin'] = origin?.toString() || ORIGIN;
    payload['PartnerId'] = partnerId;
    return payload;
  }

  postEmCalculationRequest(payload: any) {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;

    return this.http
      .post(`${this.apiEndpoint}/api/nbo/em/calculate-template?appkey=${appKey}`, payload)
      .toPromise();
  }

  getEmOfferPreviewPDF(leadId: string, variantId: number): Promise<any> {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;

    const url = `${this.apiEndpoint}/api/nbo/em/leads/${leadId}/variants/${variantId}/preview?appkey=${appKey}`;

    return this.http
      .get(url, { responseType: 'blob', observe: 'response' })
      .pipe(
        tap(response => {
          const mediaType = 'application/pdf';
          const blob = new Blob([response.body], { type: mediaType });

          saveAs(blob, BackendService.getOfferName(response.headers));
        })
      )
      .toPromise();
  }

  postEmNboRequest(payload: {}): Promise<EmobilityVariantSummaryViewModelLeadSummaryViewModel> {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;

    return this.http
      .post<EmobilityVariantSummaryViewModelLeadSummaryViewModel>(
        `${this.apiEndpoint}/api/nbo/em?appkey=${appKey}`,
        cleanEmptyProperties(BackendService.patchPayload(payload, appState.config.queryParams))
      )
      .toPromise();
  }

  postComparisonCostSummary(data: {
    EnergyDemand: number;
    CostLiterOil: number;
    CostKwhElectricity: number;
    TaxCostTonneCo2: number;
  }) {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;
    return this.http
      .post(`${this.apiEndpoint}/api/nbo/ht/heating-comparison/cost-summary?appkey=${appKey}`, data)
      .toPromise();
  }

  postComparisonCo2Emission(data: { EnergyDemand: number }) {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;
    return this.http
      .post<NboComparisonCo2ViewModel>(
        `${this.apiEndpoint}/api/nbo/ht/heating-comparison/co2-emission?appkey=${appKey}`,
        { EnergyDemand: data.EnergyDemand || 0 }
      )
      .toPromise();
  }

  postComparisonInvestment(data: { InvestmentNewHeatpump: number; HeatingPower: number }) {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;
    return this.http
      .post<NboComparisonInvestmentViewModel>(
        `${this.apiEndpoint}/api/nbo/ht/heating-comparison/investment?appkey=${appKey}`,
        {
          InvestmentNewHeatpump: data.InvestmentNewHeatpump || 0,
          HeatingPower: data.HeatingPower || 0,
        }
      )
      .toPromise();
  }

  postHtEnergyConversion(medium: string) {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;
    return this.http
      .post<NboEnergyConversionViewModel>(
        `${this.apiEndpoint}/api/nbo/ht/energy-conversion?appkey=${appKey}`,
        {
          CurrentHeatGeneratorType:
            medium || NboHeatingLeadRequest.CurrentHeatGeneratorTypeEnum.Other,
        }
      )
      .toPromise();
  }

  getBuildingPlayerAccessToken() {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;
    return this.http
      .get(`${this.apiEndpoint}/api/buildingplayer/accesstoken?appkey=${appKey}`)
      .pipe(map((accessToken: string) => encodeURIComponent(accessToken)))
      .toPromise();
  }

  postHtHeatingPower(data: {
    BuildingGroundArea: number;
    BuildingLevels: number;
    BuildingYearOfConstruction: NboHeatingLeadRequest.BuildingYearOfConstructionEnum;
  }) {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;

    const projectType: NboHeatingLeadRequest.ProjectTypeEnum =
      NboHeatingLeadRequest.ProjectTypeEnum.Replacement;

    if (
      projectType === NboHeatingLeadRequest.ProjectTypeEnum.NewBuilding ||
      !data.BuildingYearOfConstruction
    ) {
      data.BuildingYearOfConstruction =
        NboHeatingLeadRequest.BuildingYearOfConstructionEnum.NewBuilding;
    }

    return this.http
      .post(`${this.apiEndpoint}/api/nbo/ht/heating-power?appkey=${appKey}`, data)
      .toPromise();
  }

  getHtNboProductlines(
    energyDemandKwh: number,
    currentHeatDissipation: NboHeatingLeadRequest.CurrentHeatDissipationEnum
  ) {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;

    return this.http
      .get<NboProductlineViewModel[]>(
        `${this.apiEndpoint}/api/nbo/ht/productlines?appkey=${appKey}`,
        {
          params: cleanEmptyProperties({
            energyDemandKwh,
            currentHeatDissipation,
          }),
        }
      )
      .toPromise();
  }

  getMatchingTemplateNames(
    whereToInstallCharger: NboEmobilityCalculateRequest.WhereToInstallChargerEnum,
    orderType: NboEmobilityRequest.OrderTypeEnum,
    connectpv: NboEmobilityCalculateRequest.SmartEnergyConnectionEnum
  ) {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;

    const source$ = this.http.get<string[]>(
      `${this.apiEndpoint}/api/nbo/em/templates?appkey=${appKey}`,
      {
        params: cleanEmptyProperties({
          whereToInstallCharger,
          orderType,
          smartEnergyConnection: connectpv,
        }),
      }
    );

    return firstValueFrom(source$);
  }

  postHtCalculateNbo(payload: {}) {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;
    return this.http
      .post<HeatingVariantSummaryViewModel>(
        `${this.apiEndpoint}/api/nbo/ht/calculate?appkey=${appKey}`,
        cleanEmptyProperties(payload)
      )
      .toPromise();
  }

  postHtSaveNboData(payload: {}): Promise<HeatingVariantSummaryViewModelLeadSummaryViewModel> {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;

    return this.http
      .post<HeatingVariantSummaryViewModelLeadSummaryViewModel>(
        `${this.apiEndpoint}/api/nbo/ht?appkey=${appKey}`,
        cleanEmptyProperties(BackendService.patchPayload(payload, appState.config.queryParams))
      )
      .pipe(catchError(() => EMPTY))
      .toPromise();
  }

  getHtNboOfferPreviewPDF(leadId: string | number, variantId: number) {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;
    const url = `${this.apiEndpoint}/api/nbo/ht/leads/${leadId}/variants/${variantId}/preview?appkey=${appKey}`;

    return this.http
      .get(url, { responseType: 'blob', observe: 'response' })
      .pipe(
        tap(response => {
          const mediaType = 'application/pdf';
          const blob = new Blob([response.body], { type: mediaType });

          saveAs(blob, BackendService.getOfferName(response.headers));
        })
      )
      .toPromise();
  }

  postHtVariantToLead(payload: {}) {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;
    const { nboLeadIdHt } = appState.collectedData;

    if (!nboLeadIdHt) {
      console.error('No existing lead id, exiting');
      return;
    }

    return this.http
      .post<HeatingVariantSummaryViewModel[]>(
        `${this.apiEndpoint}/api/nbo/ht/${nboLeadIdHt}/variants?appkey=${appKey}`,
        cleanEmptyProperties(payload)
      )
      .toPromise();
  }

  patchHtNboPersonData(payload: {}) {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;
    const { nboLeadIdHt } = appState.collectedData;

    if (!nboLeadIdHt) {
      console.error('No existing lead id, exiting');
      return;
    }

    return this.http
      .patch(
        `${this.apiEndpoint}/api/nbo/ht/${nboLeadIdHt}?appkey=${appKey}`,
        cleanEmptyProperties(BackendService.patchPayload(payload, appState.config.queryParams))
      )
      .toPromise();
  }

  postPvCalculateNbo(payload: {}) {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;
    return this.http
      .post(
        `${this.apiEndpoint}/api/nbo/pv/calculate?appkey=${appKey}`,
        cleanEmptyProperties(payload)
      )
      .toPromise();
  }

  postPvSaveNboData(payload: {}) {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;

    return this.http
      .post(
        `${this.apiEndpoint}/api/nbo/pv?appkey=${appKey}`,
        cleanEmptyProperties(BackendService.patchPayload(payload, appState.config.queryParams))
      )
      .pipe(catchError(() => EMPTY))
      .toPromise();
  }

  getPvNboOfferPreviewPdf(leadId: string, variantId: number) {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;
    const url = `${this.apiEndpoint}/api/nbo/pv/leads/${leadId}/variants/${variantId}/preview?appkey=${appKey}`;

    return this.http
      .get(url, { responseType: 'blob', observe: 'response' })
      .pipe(
        tap(response => {
          const mediaType = 'application/pdf';
          const blob = new Blob([response.body], { type: mediaType });

          saveAs(blob, BackendService.getOfferName(response.headers));
        })
      )
      .toPromise();
  }

  postPvVariantToLead(payload: {}) {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;
    const { nboLeadIdPv } = appState.collectedData;

    if (!nboLeadIdPv) {
      console.error('No existing lead id, exiting');
      return;
    }

    return this.http
      .post<PvVariantSummaryViewModel[]>(
        `${this.apiEndpoint}/api/nbo/pv/${nboLeadIdPv}/variants?appkey=${appKey}`,
        cleanEmptyProperties(payload)
      )
      .toPromise();
  }

  patchPvNboPersonData(payload: {}) {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;
    const { nboLeadIdPv } = appState.collectedData;

    if (!nboLeadIdPv) {
      console.error('No existing lead id, exiting');
      return;
    }

    return this.http
      .patch(
        `${this.apiEndpoint}/api/nbo/pv/${nboLeadIdPv}?appkey=${appKey}`,
        cleanEmptyProperties(BackendService.patchPayload(payload, appState.config.queryParams))
      )
      .toPromise();
  }

  patchNboLead(leadId: string, payload: PatchNboViewModel) {
    const appState: IAppStateBase = this.store.getState();
    const { appKey } = appState.config.envVariables;
    return this.http
      .patch<any>(
        `${this.apiEndpoint}/api/nbo/leads/${leadId}?appkey=${appKey}`,
        cleanEmptyProperties(payload)
      )
      .toPromise();
  }
  // http://localhost:4200/heatingtype?partnerId=mt&origin=service&leadTag=service&ThirdPartyCustomerNumber=306857&FirstName=Markusys4&LastName=Wapf-Omlin22&PhoneNumber=0041%2079%20426%2063%2076&Email=mailo234oi@markus-wapf.ch&ZipCode=8424&City=Embrach&Street=In%20Langwise%2045&Language=de&Debtor_ThirdPartyCustomerNumber=129852&Debtor_FirstName=Vorname&Debtor_LastName=Verein%20Wyler%20am%20Teich&Debtor_PhoneNumber=0041796665544&Debtor_Email=mailooi78621238i8i8i8i@auftraggeber12.ch&Debtor_ZipCode=8424&Debtor_City=Embrach&Debtor_Street=In%20Langwise%2029&ServiceTechnician_ThirdPartyCustomerNumber=903343&ServiceTechnician_FirstName=C%C3%A9cile&ServiceTechnician_LastName=Dubuis&ServiceTechnician_PhoneNumber=0041%2079%20652%2037%2027&ServiceTechnician_Email=cecile.dubuis@meiertobler.com&ServiceTechnician_Region=4611%20Z%C3%BCrich%20FO&note=%3Cb%3ESIRA%20-%20Object%20address%3C%2Fb%3E%0D%0AEQ-Nummer:%20306857%0D%0AFirstName:%20Markus%0D%0ALastName:%20Wapf-Omlin%0D%0APhoneNumber:%20%2041%2079%20426%2063%2076%0D%0AEmail:%20mail@markus-wapf.ch%0D%0AZipCode:%208424%0D%0ACity:%20Embrach%0D%0AStreet:%20In%20Langwise%2045%0D%0ALanguage:%20de%0D%0A%3Cb%3ESIRA%20-%20Debitor%20address%3C%2Fb%3E%0D%0ADebtor_ThirdPartyCustomerNumber:%20129852%0D%0ADebtor_FirstName:%20Vorname%0D%0ADebtor_LastName:%20Verein%20Wyler%20am%20Teich%0D%0ADebtor_PhoneNumber:%20%2041796665544%0D%0ADebtor_Email:%20mail@auftraggeber.ch%0D%0ADebtor_ZipCode:%208424%0D%0ADebtor_City:%20Embrach%0D%0ADebtor_Street:%20In%20Langwise%2029%0D%0A%3Cb%3ESIRA%20-%20Service%20technician%20address%3C%2Fb%3E%0D%0AServiceTechnician_ThirdPartyCustomerNumber:%20903343%0D%0AServiceTechnician_FirstName:%20C%C3%A9cile%0D%0AServiceTechnician_LastName:%20Dubuis%0D%0AServiceTechnician_PhoneNumber:%20%2041%2079%20652%2037%2027%0D%0AServiceTechnician_Email:%20cecile.dubuis@meiertobler.com%0D%0AServiceTechnician_Region:%204611%20Z%C3%BCrich%20FO%0D%0A
  postDebitorAddressToLead(
    nboLeadId: string,
    payload: Partial<NboPostContactViewModel | NboPutAddressRequest>
  ) {
    if (!nboLeadId) {
      console.error('No existing lead id, exiting');
      return;
    }

    const body = cleanEmptyProperties(payload);
    const url = `${this.apiEndpoint}/api/nbo/leads/${nboLeadId}/addresses/debitor`;

    return !isEmptyValue(body) && (body.Email || body.ThirdPartyCustomerNumber)
      ? this.http
          .put<NboPostContactViewModel>(
            url,
            cleanEmptyProperties({
              ...body,
              PersonThirdPartyCustomerNumber: body.ThirdPartyCustomerNumber,
              CompanyThirdPartyCustomerNumber: body.ThirdPartyCustomerNumber,
              PersonEmail: body.Email,
            })
          )
          .pipe(
            catchError(() => this.http.post<NboPostContactViewModel>(url, body)),
            catchError(() => EMPTY)
          )
          .toPromise()
      : Promise.resolve(null);
  }

  postAdditionalAddressToLead(
    nboLeadId: string,
    payload: NboPostContactViewModel | Partial<NboPostContactViewModel>,
    addressType: NboLeadAddressViewModel.AddressTypeEnum
  ) {
    if (!nboLeadId) {
      console.error('No existing lead id, exiting');
      return;
    }

    const body = cleanEmptyProperties(payload);
    const url = `${this.apiEndpoint}/api/nbo/leads/${nboLeadId}/addresses/additional?addressType=${addressType}`;

    return !isEmptyValue(body) && addressType && body.Email
      ? this.http
          .put<NboPostContactViewModel>(url, {
            ...body,
            PersonThirdPartyCustomerNumber: body.ThirdPartyCustomerNumber,
            PersonEmail: body.Email,
          })
          .pipe(
            catchError(() => this.http.post<NboPostContactViewModel>(url, body)),
            catchError(() => EMPTY)
          )
          .toPromise()
      : Promise.resolve(null);
  }

  putPorscheAddressToLead(
    nboLeadId: string,
    addressType: NboLeadAddressViewModel.AddressTypeEnum,
    dealer: string
  ) {
    if (!nboLeadId) {
      console.error('No existing lead id, exiting');
      return;
    }

    const url = `${this.apiEndpoint}/api/nbo/leads/${nboLeadId}/addresses/porsche-address?addressType=${addressType}&dealer=${dealer}`;

    return dealer && addressType
      ? this.http
          .put<NboPostContactViewModel>(url, null)
          .pipe(catchError(() => EMPTY))
          .toPromise()
      : Promise.resolve(null);
  }
}
