import { NboAddressViewModel } from '@api-cc';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ILanguageSpecific } from '@theia-cc/shared/models';

export const ORIGIN = 'Customer Client';
export const ORIGIN_CALLBACK = 'Kontaktformular';

export const CONFIG_DEFAULT_CONTACT_PHONE: ILanguageSpecific<string> = {
  de: '+41 32 552 81 18',
  fr: '+41 32 552 81 18',
  it: '+41 32 552 81 18',
  en: '+41 32 552 81 18',
};

export interface IOption {
  id?: string;
  value?: string;
  label?: string;
}

export const CALLBACK_TIME_OPTIONS: IOption[] = [
  {
    id: '1',
    value: 'IsCallbackTimeMorning',
    label: _('CONTACT_FORM.CALLBACK_MORNING'),
  },
  {
    id: '2',
    value: 'IsCallbackTimeAfternoon',
    label: _('CONTACT_FORM.CALLBACK_AFTERNOON'),
  },
  {
    id: '3',
    value: 'IsCallbackTimeEvening',
    label: _('CONTACT_FORM.CALLBACK_EVENING'),
  },
];

export const TITLE_OPTIONS: IOption[] = [
  {
    id: '1',
    value: NboAddressViewModel.TitleEnum.Sir,
    label: _('CONTACT_FORM.MR'),
  },
  {
    id: '2',
    value: NboAddressViewModel.TitleEnum.Madam,
    label: _('CONTACT_FORM.MRS'),
  },
  {
    id: '3',
    value: NboAddressViewModel.TitleEnum.Family,
    label: _('CONTACT_FORM.FAMILY'),
  },
  {
    id: '4',
    value: NboAddressViewModel.TitleEnum.Company,
    label: _('CONTACT_FORM.COMPANY'),
  },
];
