/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PublicAddressViewModel {
  Title?: PublicAddressViewModel.TitleEnum;
  LastName?: string;
  FirstName?: string;
  AdditionalName?: string;
  CompanyName?: string;
  AdditionalCompanyName?: string;
  PhoneNumber?: string;
  MobilePhoneNumbers?: Array<string>;
  WorkPhoneNumbers?: Array<string>;
  PrivatePhoneNumbers?: Array<string>;
  SwitchboardPhoneNumbers?: Array<string>;
  OtherPhoneNumbers?: Array<string>;
  PrimaryPhoneNumbers?: Array<string>;
  DirectPhoneNumbers?: Array<string>;
  Email?: string;
  WorkEmails?: Array<string>;
  PrivateEmails?: Array<string>;
  OtherEmails?: Array<string>;
  PrimaryEmails?: Array<string>;
  ReminderEmails?: Array<string>;
  Street?: string;
  ZipCode?: string;
  City?: string;
  CareOfName?: string;
  PostOfficeBox?: string;
  ThirdPartyCustomerNumber?: string;
}
export namespace PublicAddressViewModel {
  export type TitleEnum = 'None' | 'Sir' | 'Madam' | 'Company' | 'Family';
  export const TitleEnum = {
    None: 'None' as TitleEnum,
    Sir: 'Sir' as TitleEnum,
    Madam: 'Madam' as TitleEnum,
    Company: 'Company' as TitleEnum,
    Family: 'Family' as TitleEnum,
  };
}
