import {
  IConstants,
  ROOF_3D_PLUGIN_COMMON_SETTINGS,
  WizardProductKeyEnum,
} from '@theia-cc/shared/helpers';

export const CONSTANTS_PV = <IConstants>{
  wizardProductKey: WizardProductKeyEnum.pv,
  APP_MAIN_ENTRY_STEP: 'buildingtype',
  APP_ALLOWED_ENTRY_STEPS: ['buildingtype', 'contact', 'bookmarklet'],
  moduleLifetimeYears: 30,
  minRoofArea: 23,
  optimalRoofArea: 80,
  maxRoofArea: 150,
  pvxKwhThreshold: 28640,
  pvxKamLowerThreshold: 600,
  basePricePerInstallation: 7906,
  pricePerAdditionalKwp: 900,
  roofAreaPrices: {
    large: "32'000",
    medium: "21'000",
    small: "13'000",
  },
  maxPowerPerModule: 0.305,
  areaPerModule: 1.7,
  basePowerConsumption: 1850,
  powerConsumptionPerPerson: 850,
  powerConsumptionPerApartment: 3300,
  defaultHighDrainSystemsKwh: 7000,
  kwhPerRoofM2: 178,
  slopeKwpPerM2: 0.165,
  flatKwpPerM2: 0.148,
  minKwp: 30,
  powerCostLow: 0.1,
  powerCostHigh: 0.17,
  averageProductionKwp: 950,
  apartmentBuildingHighDrainSystemsKwh: {
    ELECTRIC_HEATING: 15000,
    HEATPUMP: 5000,
    ELECTROBOILER: 3000,
    EMOBILITY_CHARGING: 3000,
    POOL_SAUNA: 5000,
  },
  pvEarningsKwhM2: 180,
  pvEarningsChf: 700,
  pvEarningsKwh: 7000,
};

export const ROOF_3D_PLUGIN_PV_SETTINGS = {
  ...ROOF_3D_PLUGIN_COMMON_SETTINGS,
  roofColoring: true,
  showBestRoof: true,
  roofSelectable: 2,
  roofSelectedBorderColor: '#ff00b7',
  roofSelectedBorderWidth: 22,
};
