/**
 * Helion.Api.Nbo
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1-0-00
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { NboHeatingLeadRequest } from './nboHeatingLeadRequest';
import { NboHeatingVariantRequest } from './nboHeatingVariantRequest';
import { SavePersonNboViewModel } from './savePersonNboViewModel';

export interface SaveNboHeatingRequest {
  PersonData?: SavePersonNboViewModel;
  /**
   * The comment that will be set on the newly created lead
   */
  LeadComment?: string;
  /**
   * The tag that will be set on the newly created lead
   */
  LeadTag?: string;
  /**
   * The tracers that will be set on the newly created lead
   */
  LeadTracers?: string;
  /**
   * The url to share the nbo
   */
  ShareUrl?: string;
  PlannedImplementationTime?: SaveNboHeatingRequest.PlannedImplementationTimeEnum;
  LeadRating?: SaveNboHeatingRequest.LeadRatingEnum;
  /**
   * Whether the customer has selected callback time \"morning\". Default is false.
   */
  IsCallbackTimeMorning?: boolean;
  /**
   * Whether the customer has selected callback time \"afternoon\". Default is false.
   */
  IsCallbackTimeAfternoon?: boolean;
  /**
   * Whether the customer has selected callback time \"evening\". Default is false.
   */
  IsCallbackTimeEvening?: boolean;
  /**
   * Whether the customer has selected callback time \"weekend\". Default is false.
   */
  IsCallbackTimeWeekend?: boolean;
  /**
   * Where this lead comes from. If no value is specified \"Customer Client\" will be used
   */
  Origin?: string;
  /**
   * The partner ID, will be set on the newly created lead
   */
  PartnerId?: string;
  /**
   * The 3D image, encoded as a string, will be set on the newly created lead
   */
  SituationImage3D?: string;
  /**
   * The hubspot tracking id, will be set if available
   */
  HubspotTrackingId?: string;
  /**
   * Provider lead identifier, will be set if provider has one.
   */
  ProviderLeadId?: string;
  ThirdPartyCustomerNumber?: string;
  /**
   * Public lead id of a related lead (optional).
   */
  RelatedLeadPublicId?: string;
  LeadStatus?: SaveNboHeatingRequest.LeadStatusEnum;
  LeadRequest: NboHeatingLeadRequest;
  /**
   * Object and metadata for variants which shall be created
   */
  VariantRequests?: Array<NboHeatingVariantRequest>;
}
export namespace SaveNboHeatingRequest {
  export type PlannedImplementationTimeEnum =
    | 'AsSoonAsPossible'
    | 'FourToTwelveMonths'
    | 'OneToTwoYears'
    | 'Unknown';
  export const PlannedImplementationTimeEnum = {
    AsSoonAsPossible: 'AsSoonAsPossible' as PlannedImplementationTimeEnum,
    FourToTwelveMonths: 'FourToTwelveMonths' as PlannedImplementationTimeEnum,
    OneToTwoYears: 'OneToTwoYears' as PlannedImplementationTimeEnum,
    Unknown: 'Unknown' as PlannedImplementationTimeEnum,
  };
  export type LeadRatingEnum = 'Zero' | 'One' | 'Two' | 'Three' | 'Four' | 'Five';
  export const LeadRatingEnum = {
    Zero: 'Zero' as LeadRatingEnum,
    One: 'One' as LeadRatingEnum,
    Two: 'Two' as LeadRatingEnum,
    Three: 'Three' as LeadRatingEnum,
    Four: 'Four' as LeadRatingEnum,
    Five: 'Five' as LeadRatingEnum,
  };
  export type LeadStatusEnum =
    | 'New'
    | 'OfferDownload'
    | 'Callback'
    | 'Incomplete'
    | 'Complete'
    | 'AutoOffer';
  export const LeadStatusEnum = {
    New: 'New' as LeadStatusEnum,
    OfferDownload: 'OfferDownload' as LeadStatusEnum,
    Callback: 'Callback' as LeadStatusEnum,
    Incomplete: 'Incomplete' as LeadStatusEnum,
    Complete: 'Complete' as LeadStatusEnum,
    AutoOffer: 'AutoOffer' as LeadStatusEnum,
  };
}
